<template>
  <div>
    <!--    <router-view></router-view>-->
    <b-card class="mb-0" no-body>
      <div class="">
        <b-button
            class=" float-right mb-1"
            size="sm" 
            variant="primary"
            @click="toggleSideBars()"
        >
          <span class="align-middle fs-4">+ Create Role</span>
        </b-button>
      
      </div>
      <div>
        <b-overlay
            :show="tableLoading"
            rounded="md"
        >
          <b-row>

            <b-col>
              <b-table
                  :current-page="currentPage"
                  :per-page="pagination.perPage"
                  :fields="fields"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :items="getRole"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  hover
                  ref="table"
                  class="mobile_table_css"
                  responsive
                  >
                  <template #cell(Name)="data" >
                  <div style="width:200px" >
                  {{ data.item.Name }}
                  </div>
                </template>

                <template #cell(Profession)="data" >
                  <div >
                  {{ data.item.Profession.join(' , ') }}
                  </div>
                </template>

                <template #cell(Type)="data" >
                  <div style="width:100px" >
                  {{ data.item.Type }}
                  </div>
                </template>

                <template #cell(action)="data">
                  <b-row>
                    <b-col class="">
                      <b-button
                          size="23"
                          variant="outline-white"
                          class="btn-icon shadow-sm bg-white text-primary"
                          @click="editShifts(data.item)"
                          v-b-tooltip.hover.top="'Edit'">

                        <feather-icon
                            size="18"
                            icon="EditIcon"
                        />
                      </b-button>
                    </b-col>
<!--                    <b-col class=" ml-1">-->
<!--                      <b-button-->
<!--                          size="23"-->
<!--                          variant="outline-white"-->
<!--                          class="btn-icon shadow-sm bg-white text-danger"-->
<!--                          @click="removeService(data.item.id)"-->
<!--                          v-b-tooltip.hover.top="'Delete'">-->

<!--                        <feather-icon-->
<!--                            size="18"-->
<!--                            icon="TrashIcon" />-->
<!--                      </b-button>-->
<!--                    </b-col>-->
                  </b-row>
                </template>


              </b-table>
            </b-col>
          </b-row>
          <!--                pagination-->
          <div class="mx-1 mb-2">
            <b-row>
              <b-col cols="12" v-if="noDataTable === 0" class="text-center">
                <span >No data for preview</span>
              </b-col>

              <!-- Pagination -->

              <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
                <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
              </b-col>

              <!-- Pagination -->

              <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

                <b-pagination
                    v-model="currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    class="mb-0 mt-1 mt-sm-0 "
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item">

                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18"/>
                  </template>

                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18"/>
                  </template>

                </b-pagination>

              </b-col>


            </b-row>
          </div>
        </b-overlay>
      </div>
    </b-card>


    <AddRoles
        ref="AddRoleSideBar"
        :isAddRoles="isAddRoles"
        @toggleSideBars="toggleSideBars()"
        @dataRefresh="dataRefresh()"
    />

    <EditRoleSideBar
        ref="EditLocationSideBar"
        :selected-data = "selectedData"
        :isEditRoleSidebarActive="isEditRoleSidebarActive"
        @editShifts() ="editShifts()"
        @dataRefresh="dataRefresh()"
    />


  </div>
</template>

<script>


import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia, BOverlay,
  BPagination,
  BRow,
  BTable,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import AddRoles from '@/views/settings/sidebars/addRolesSidebar.vue'
import EditRoleSideBar from '@/views/settings/sidebars/editeRoleSidebar.vue'
import settings from '@/apis/modules/settings'
import Ripple from 'vue-ripple-directive'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import MomentMixin from '@/mixins/MomentMixin'
import SettingsAPI from "@/apis/modules/settings";

export default {
  name: 'addRole',
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],
  components: {
    AddRoles,
    EditRoleSideBar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    vSelect,
    FeatherIcon,
    BOverlay
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data: function () {
    return {
      selectedData: {},
      isAddRoles: false,
      isEditRoleSidebarActive: false,
      tableLoading: false,
      noDataTable: '',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'Name',
          name: 'Service'

        },
        {
          key: 'Profession',
          name: 'Start Time'
        },
        {
          key: 'Type',
          name: 'End Time'
        },
        // {
        //   key: 'PCN_Id',
        //   name: 'Break',
        //   sortable: false
        // },
        // {
        //   key: 'Practice_Id',
        //   name: 'Note',
        //   sortable: false
        // },
        {
          key: 'action'
        }
      ],
      items: []
    }
  },

 async mounted(){
    await this.getJobRoleList()
  },

  methods : {
    toggleSideBars() {
      this.isAddRoles = !this.isAddRoles
    },

    editShifts(item){
      this.selectedData = item;
      this.isEditRoleSidebarActive = !this.isEditRoleSidebarActive
    },

    dataRefresh(){
      this.$refs.table.refresh()
      this.isAddRoles = false
      this.isEditRoleSidebarActive = false

    },

    async getJobRoleList() {
      try {
        const Response = await SettingsAPI.getJobRoleList();
        this.roleList = Response.data.data.map((x) => ({
          label: x.name,
          value: x.id,
        }))

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },

    async getRole(){
      try {
        this.tableLoading = true
        await this.getJobRoleList()
        const Response= await settings.getRoles(this.currentPage,10)
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          Name: x.name,
          Practice_Id: x.practice_id? x.practice_id:'N/A',
          PCN_Id: x.PCN_Id? x.practice_id:'N/A',
          Profession:this.filterLabel(x.professions),
          Professions: x.professions,
          Type: x.type,
        }))
        this.noDataTable = Response.data.data.length
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage= paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false

        return dataArray
      }catch (error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }

    },

      filterLabel(profession){
        let array = [];
        array = profession.map((x)=>{
         return (this.roleList.find(e=>e.value === x)).label
        })
        return array
      },
    async removeService(id){
      // try {
      //   this.tableLoading = true
      //   this.$swal({
      //     title: 'Are you sure to delete this shift template?',
      //     text: "This action cannot be reverted.",
      //     icon: 'warning',
      //     showCancelButton: true,
      //     confirmButtonText: 'Yes, delete',
      //     cancelButtonText: 'No, go back',
      //     customClass: {
      //       confirmButton: 'btn btn-primary',
      //       cancelButton: 'btn btn-outline-primary ml-1',
      //     },
      //     buttonsStyling: false,
      //   }).then(async result => {
      //     if (result.value) {
      //       await settings.removeShiftTemplate(id)
      //       this.$refs.table.refresh()
      //       this.$swal({
      //         icon: 'success',
      //         title: 'Deleted!',
      //         text: '',
      //         customClass: {
      //           confirmButton: 'btn btn-success',
      //         },
      //       })
      //     } else if (result.dismiss === 'cancel') {
      //       this.$refs.table.refresh()
      //       this.tableLoading = false
      //       this.$swal({
      //         title: 'Cancelled',
      //         text: ' ',

      //         icon: 'error',
      //         customClass: {
      //           confirmButton: 'btn btn-success',
      //         },
      //       })
      //     }
      //   })
      //   //  await settings.removeServiceForPractice(id)
      //   // this.showSuccessMessage('Remove service Successful')
      //   // this.$refs.table.refresh()

      // }catch (error){
      //   this.convertAndNotifyError(error)
      // }
    }
  }
}

</script>
<style scoped>

</style>
