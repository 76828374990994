var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("b-table", {
            ref: "table",
            staticClass: "mobile_table_css",
            attrs: {
              "current-page": _vm.currentPage,
              fields: _vm.fields,
              items: _vm.getUsers,
              "per-page": _vm.pagination.perPage,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              "sort-direction": _vm.sortDirection,
              hover: "",
              responsive: ""
            },
            on: {
              "update:sortBy": function($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function($event) {
                _vm.sortDesc = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "cell(first_name)",
                fn: function(data) {
                  return [
                    _c(
                      "ul",
                      { staticClass: "list-inline mb-0" },
                      [
                        _c(
                          "li",
                          { staticClass: "list-inline-item" },
                          [
                            _c("b-avatar", {
                              staticClass: "pull-up",
                              attrs: {
                                src: _vm.getUserImage(data.item.id, true)
                              }
                            })
                          ],
                          1
                        ),
                        _c("li", { staticClass: "list-inline-item" }, [
                          _vm._v(_vm._s(data.item.first_name))
                        ]),
                        _vm._l(data.item.role, function(role) {
                          return _c("b-badge", {
                            key: role.id,
                            staticClass: "ml-1",
                            staticStyle: { padding: "8px" },
                            attrs: { variant: "light-info" }
                          })
                        })
                      ],
                      2
                    )
                  ]
                }
              },
              {
                key: "cell(type_of_access)",
                fn: function(data) {
                  return _vm._l(data.item.type_of_access, function(
                    type,
                    index
                  ) {
                    return _c(
                      "span",
                      { key: index },
                      [
                        _c(
                          "b-badge",
                          { attrs: { variant: "light-primary ml-1" } },
                          [_vm._v(_vm._s(type.name))]
                        ),
                        _c("br")
                      ],
                      1
                    )
                  })
                }
              },
              {
                key: "cell(is_archive)",
                fn: function(data) {
                  return [
                    _c("b-badge", { attrs: { variant: "light-danger" } }, [
                      _vm._v(_vm._s(data.item.is_archive))
                    ])
                  ]
                }
              },
              {
                key: "cell(action1)",
                fn: function(data) {
                  return [
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _c(
                        "div",
                        { staticClass: "float-left" },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.top",
                                  value: "Active staff",
                                  expression: "'Active staff'",
                                  modifiers: { hover: true, top: true }
                                }
                              ],
                              staticClass: "btn-icon",
                              attrs: {
                                variant: "gradient-primary mt-1",
                                size: "sm"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addAgainUser(data.item.id)
                                }
                              }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "UserPlusIcon" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("div")
        ],
        1
      ),
      _vm.pagination.totalRows === 0
        ? _c("b-col", { staticClass: "text-center", attrs: { cols: "12" } }, [
            _c("span", [_vm._v("No data for preview")])
          ])
        : _vm._e(),
      _c(
        "b-col",
        {
          staticClass:
            "\n        d-flex\n        align-items-center\n        justify-content-center justify-content-sm-start\n      ",
          attrs: { cols: "12", sm: "6" }
        },
        [
          _vm.pagination.totalRows !== 0
            ? _c("span", { staticClass: "text-muted" }, [
                _vm._v(
                  "Showing " +
                    _vm._s(_vm.pagination.from) +
                    " to " +
                    _vm._s(_vm.pagination.to) +
                    " of " +
                    _vm._s(_vm.pagination.totalRows) +
                    " entries"
                )
              ])
            : _vm._e()
        ]
      ),
      _c(
        "b-col",
        {
          staticClass:
            "\n        d-flex\n        align-items-center\n        justify-content-center justify-content-sm-end\n      ",
          attrs: { cols: "12", sm: "6" }
        },
        [
          _c("b-pagination", {
            staticClass: "mb-0 mt-1 mt-sm-0",
            attrs: {
              "per-page": _vm.pagination.perPage,
              "total-rows": _vm.pagination.totalRows,
              "first-number": "",
              "last-number": "",
              "next-class": "next-item",
              "prev-class": "prev-item"
            },
            scopedSlots: _vm._u([
              {
                key: "prev-text",
                fn: function() {
                  return [
                    _c("feather-icon", {
                      attrs: { icon: "ChevronLeftIcon", size: "18" }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "next-text",
                fn: function() {
                  return [
                    _c("feather-icon", {
                      attrs: { icon: "ChevronRightIcon", size: "18" }
                    })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }