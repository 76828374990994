<template>
  <div>
    <hr>
    <b-card class="">
      <b-row>
        <b-col cols="5">
          <ul class="list-inline mb-0 pt-1">
            <li class="list-inline-item">
              <b-form-checkbox
                  v-model="is_allow_cancel_48_hours"
                  name="check-button"
                  switch
                  v-on:change="toggle"
              >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
              </b-form-checkbox>
            </li>
            <li class="list-inline-item text-secondary"> Prevent shift cancellation within 48 hours </li>
          </ul>

        </b-col>
        <b-col cols="7">
          <div class="d-flex ml-1">
            <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                @click="stateChange()"
                size="sm"

            >
              Save
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="text-primary ml-1 shadow"
                type="button"
                variant="outline-white"
                @click="cancelData()"
                size="sm"

            >
              Cancel
            </b-button> -->
          </div>
        </b-col>



        <!-- Pagination -->
      </b-row>
    </b-card>
    <hr>
    <b-card class="">
      <b-row>
        <b-col cols="5">
          <ul class="list-inline mb-0 pt-1">
            <li class="list-inline-item">
              <b-form-checkbox
                  v-model="vacant_shifts_applications_require_approval"
                  name="check-button"
                  switch
                  v-on:change="toggle1"
              >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
              </b-form-checkbox>
            </li>
            <li class="list-inline-item text-secondary">Staff applications for shifts do not require approval</li>
          </ul>

        </b-col>
        <b-col cols="7">
          <div class="d-flex ml-1">
            <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                @click="stateChangeVacant()"
                size="sm"
            >
              Save
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="text-primary ml-1 shadow"
                type="button"
                variant="outline-white"
                @click="cancelVacantData()"
                size="sm"
            >
              Cancel
            </b-button> -->
          </div>
        </b-col>



        <!-- Pagination -->
      </b-row>
    </b-card>
        <hr>
    <b-card class="">
      <b-row>
        <b-col cols="5">
          <ul class="list-inline mb-0 pt-1">
            <li class="list-inline-item">
              <b-form-checkbox
                  v-model="is_approve_shift_swap"
                  name="check-button"
                  switch
                  @change="ShiftSwapStateChange"
              >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
              </b-form-checkbox>
            </li>
            <li class="list-inline-item text-secondary"> Automatically approve shift swaps</li>
          </ul>

        </b-col>
      </b-row>
    </b-card>
  </div>
<!--  <b-row>-->

<!--    <b-col cols="12">-->
<!--      <b-table-->
<!--          :fields="fields"-->
<!--          :filter-included-fields="filterOn"-->
<!--          :items="items"-->
<!--          class="mobile_table_css"-->
<!--      >-->

<!--             &lt;!&ndash; ResetPassword $ Button &ndash;&gt;-->

<!--        <template #cell(msg)="data">-->

<!--            <ul class="list-inline mb-0 pt-1">-->
<!--               <li class="list-inline-item">-->
<!--                   <b-form-checkbox-->
<!--                       v-model="is_allow_cancel_48_hours"-->
<!--                        name="check-button"-->
<!--                        switch-->
<!--                      >-->
<!--                        <span class="switch-icon-left">-->
<!--                          <feather-icon icon="CheckIcon" />-->
<!--                        </span>-->
<!--                        <span class="switch-icon-right">-->
<!--                          <feather-icon icon="XIcon" />-->
<!--                        </span>-->
<!--                    </b-form-checkbox>-->
<!--                  </li>-->
<!--               <li class="list-inline-item text-secondary"> Prevent shift cancellation within 48 hours </li>-->
<!--           </ul>-->
<!--        </template>-->

<!--      </b-table>-->
<!--      <hr>-->
<!--    </b-col>-->
<!--    <div class="d-flex ml-1">-->
<!--            <b-button-->
<!--                v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
<!--                type="submit"-->
<!--                variant="primary"-->
<!--                @click="stateChange()"-->
<!--            >-->
<!--              Save-->
<!--            </b-button>-->
<!--            <b-button-->
<!--                v-ripple.400="'rgba(186, 191, 199, 0.15)'"-->
<!--                class="text-primary ml-1 shadow"-->
<!--                type="button"-->
<!--                variant="outline-white"-->
<!--                @click="cancelData()"-->
<!--            >-->
<!--              Cancel-->
<!--            </b-button>-->
<!--          </div>-->
<!--    &lt;!&ndash; Pagination &ndash;&gt;-->
<!--  </b-row>-->
</template>

<script>

import {BFormCheckbox, BDropdown, BDropdownItem, BIconToggleOn } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'
import settings from '@/apis/modules/settings'
export default {
  components: {
    BCard,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BIconToggleOn,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      is_approve_shift_swap:false,
      vacant_shifts_applications_require_approval:false,
      vacant_shifts_applications_require_approval_saved:null,
      is_allow_cancel_48_hours:false,
      is_allow_cancel_48_hours_saved:null,
      is_approve_shift_swap_saved:null,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      shiftCancelSate: true,
      fields: [
        {
          key: 'msg',
          label: 'Shifts cannot be cancelled within 24 hours of the shift start time by default',
        },


      ],
      items: [

        {
          'msg': 'Allow shift cancellation within 24 hours',
        },

      ]
    }
  },
  methods:{
    toggle: function() {
      this.stateChange()
  },
  toggle1: function() {
      this.stateChangeVacant()
  },
    async stateChangeVacant(id) {
      try {
        let message = ''
        if(this.vacant_shifts_applications_require_approval === true){
          message = `Are you sure you want to allow Vacant shifts applications not require approval?`
        }else{
          message = `Are you sure you don't want to allow Vacant shifts applications not require approval?`
        }
        if (this.vacant_shifts_applications_require_approval_saved !== this.vacant_shifts_applications_require_approval){
          this.$swal({
            title: message,
            text: '',
            imageUrl: require('@/assets/images/icons/publish.png'),
            imageWidth: 80,
            imageHeight: 80,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No, go back',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1'
            },
            buttonsStyling: false
          }).then(async result => {
            if (result.value) {
              await settings.changeStateVacant()
              // await this.getPracticeShiftVacant()
            }else{
              this.vacant_shifts_applications_require_approval = !this.vacant_shifts_applications_require_approval
            }
          })
        }else {
          this.showErrorMessage('Please change the current state and save.')
        }

      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    async stateChange(id) {
      try {
        let message = ''
        if(this.is_allow_cancel_48_hours === true){
          message = `Are you sure you want to allow prevent shift cancellation within 48 hours?`
        }else{
          message = `Are you sure you don't want to allow prevent shift cancellation within 48 hours?`
        }
        if (this.is_allow_cancel_48_hours_saved !== this.is_allow_cancel_48_hours){
          this.$swal({
            title: message,
            text: '',
            imageUrl: require('@/assets/images/icons/publish.png'),
            imageWidth: 80,
            imageHeight: 80,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No, go back',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1'
            },
            buttonsStyling: false
          }).then(async result => {
            if (result.value) {
              await settings.changeStateCancelAllow()
              // await this.getShiftCancelState()
            }else{
              this.is_allow_cancel_48_hours = !this.is_allow_cancel_48_hours
            }
          })
        }else {
          this.showErrorMessage('Please change the current state and save.')
        }

      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    // cancelVacantData(){
    //   this.vacant_shifts_applications_require_approval = this.vacant_shifts_applications_require_approval_saved
    // },

    async ShiftSwapStateChange() {
      try {
        let message = ''
        if(this.is_approve_shift_swap === true){
          message = `Are you sure you want to allow prevent shift swap?`
        }else{
          message = `Are you sure you don't want to allow prevent shift swap?`
        }
          this.$swal({
            title: message,
            text: '',
            imageUrl: require('@/assets/images/icons/publish.png'),
            imageWidth: 80,
            imageHeight: 80,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No, go back',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1'
            },
            buttonsStyling: false
          }).then(async result => {
            if (result.value) {
              await settings.changeStateSwapAllow()
              // this.is_approve_shift_swap = this.is_approve_shift_swap
            }else{
               this.is_approve_shift_swap = !this.is_approve_shift_swap
            }
          })

      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    
    async getShiftVacantState(){
      try {

        const response = await settings.getPracticeShiftVacant()
        this.vacant_shifts_applications_require_approval = response.data.auto_approval_shifts? 'true':'false'
        this.vacant_shifts_applications_require_approval_saved = response.data.auto_approval_shifts?'true':'false'
      }catch (error){
        this.convertAndNotifyError(error)
      }
    },
    async getShiftCancelState(){
      try {

        const response = await settings.getPracticeShiftCancelState()
        this.is_allow_cancel_48_hours = response.data.allow_shift_cancellation_within_48_hours? 'true':'false'
        this.is_allow_cancel_48_hours_saved = response.data.allow_shift_cancellation_within_48_hours?'true':'false'
      }catch (error){
        this.convertAndNotifyError(error)
      }
    },

    async getShiftSwapState(){
      try {

        const response = await settings.getPracticeShiftSwapState()
        this.is_approve_shift_swap = response.data.allow_shift_swaps? 'true':'false'
        // this.is_approve_shift_swap_saved = response.data.is_approve_shift_swap_saved?'true':'false'
      }catch (error){
        this.convertAndNotifyError(error)
      }
    },
    // cancelData(){
    //   this.is_allow_cancel_48_hours = this.is_allow_cancel_48_hours_saved
    // }
    },

  mounted() {
     this.getShiftCancelState()
     this.getShiftVacantState()
     this.getShiftSwapState()

  },

}
</script>
<style>
div#dropdown-1 .btn-outline-danger {
  border: none !important;
}
</style>
