var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "div",
          [
            _c(
              "b-form",
              [
                _c("b-row", [_c("b-col", { attrs: { cols: "12" } })], 1),
                _c(
                  "b-row",
                  { staticClass: "settings-block bg-light-blue mb-1 py-1" },
                  [
                    _c("b-col", { attrs: { md: "3", lg: "7" } }, [
                      _c("div", { staticClass: "font-weight-bold ml-2" }, [
                        _c("p", [_vm._v("ROLE")])
                      ])
                    ]),
                    _c("b-col", { attrs: { md: "9", lg: "5" } }, [
                      _c(
                        "div",
                        { staticClass: "font-weight-bold txt-dark" },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { md: "1" } }),
                              _c("b-col", { attrs: { md: "3" } }, [
                                _c("p", [_vm._v("STANDARD RATE P/H")])
                              ]),
                              _c("b-col", { attrs: { md: "3" } }, [
                                _c("p", [_vm._v("ENHANCED RATE P/H")])
                              ]),
                              _c("b-col", { attrs: { md: "3" } }, [
                                _c("p", [_vm._v("LOCUM RATE P/H")])
                              ]),
                              _c("b-col", { attrs: { md: "1" } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-overlay",
              { attrs: { show: _vm.formLoading, rounded: "sm" } },
              [
                _c(
                  "b-form",
                  {
                    ref: "form",
                    staticClass: "repeater-form",
                    style: { height: _vm.trHeight },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.repeateAgain($event)
                      }
                    }
                  },
                  [
                    _c(
                      "validation-observer",
                      { ref: "CreateJobRoleForm" },
                      _vm._l(_vm.items, function(item, index) {
                        return _c(
                          "b-row",
                          {
                            key: item.id,
                            ref: "row",
                            refInFor: true,
                            attrs: { id: item.id }
                          },
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "align-self-center",
                                attrs: { md: "3", lg: "7" }
                              },
                              [
                                _c("div", { staticClass: "ml-2 set-mb" }, [
                                  _c(
                                    "div",
                                    { staticClass: "set-mb-head d-md-none" },
                                    [_vm._v("ROLE")]
                                  ),
                                  _c("h6", [_vm._v(_vm._s(item.role))])
                                ])
                              ]
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "9", lg: "5" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", { attrs: { md: "1" } }),
                                    _c("b-col", { attrs: { md: "3" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "set-mb" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "set-mb-head d-md-none"
                                            },
                                            [_vm._v("STANDARD PATH P/H")]
                                          ),
                                          _c(
                                            "b-form-group",
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Standard path p/h",
                                                  rules: "numeric"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-input-group",
                                                            {
                                                              staticClass:
                                                                "input-group-merge"
                                                            },
                                                            [
                                                              _c(
                                                                "b-input-group-prepend",
                                                                {
                                                                  attrs: {
                                                                    "is-text":
                                                                      ""
                                                                  }
                                                                },
                                                                [_vm._v(" £ ")]
                                                              ),
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "standard",
                                                                    placeholder:
                                                                      "0.00"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.standard_rate_p_h,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "standard_rate_p_h",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "item.standard_rate_p_h"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("b-col", { attrs: { md: "3" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "set-mb" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "set-mb-head d-md-none"
                                            },
                                            [_vm._v("ENHANCED RATE P/H")]
                                          ),
                                          _c(
                                            "b-form-group",
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Enhanced Rate p/h",
                                                  rules: "numeric"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-input-group",
                                                            {
                                                              staticClass:
                                                                "input-group-merge"
                                                            },
                                                            [
                                                              _c(
                                                                "b-input-group-prepend",
                                                                {
                                                                  attrs: {
                                                                    "is-text":
                                                                      ""
                                                                  }
                                                                },
                                                                [_vm._v(" £ ")]
                                                              ),
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "enchanced",
                                                                    placeholder:
                                                                      "0.00"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.enhanced_rate_p_h,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "enhanced_rate_p_h",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "item.enhanced_rate_p_h"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("b-col", { attrs: { md: "3" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "set-mb" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "set-mb-head d-md-none"
                                            },
                                            [_vm._v("LOCUM RATE P/H")]
                                          ),
                                          _c(
                                            "b-form-group",
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Locum Rate p/h",
                                                  rules: "numeric"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-input-group",
                                                            {
                                                              staticClass:
                                                                "input-group-merge"
                                                            },
                                                            [
                                                              _c(
                                                                "b-input-group-prepend",
                                                                {
                                                                  attrs: {
                                                                    "is-text":
                                                                      ""
                                                                  }
                                                                },
                                                                [_vm._v(" £ ")]
                                                              ),
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "locume",
                                                                    placeholder:
                                                                      "0.00"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.locum_rate_p_h,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "locum_rate_p_h",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "item.locum_rate_p_h"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "1" } },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: "Delete Role",
                                                expression: "'Delete Role'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true
                                                }
                                              }
                                            ],
                                            staticClass:
                                              "btn-icon bg-white text-secondary",
                                            attrs: {
                                              size: "23",
                                              variant: "danger"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeItem(
                                                  index,
                                                  item.job_role_id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                icon: "TrashIcon",
                                                size: "18"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("b-col", { attrs: { cols: "12" } }, [_c("hr")])
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "b-row",
          [
            _c("b-col", { attrs: { md: "3", lg: "7" } }, [
              _c(
                "div",
                { staticClass: "set-mb" },
                [
                  _c("div", { staticClass: "set-mb-head d-md-none" }, [
                    _vm._v("ROLE")
                  ]),
                  _c(
                    "b-dropdown",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      staticStyle: {
                        "background-color": "rgba(255, 225, 225, 0.1)"
                      },
                      attrs: {
                        id: "dropdown-1",
                        plain: "",
                        drop: "",
                        text: "+ Add Role ",
                        variant: " bg-white text-primary"
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        { staticClass: "mx-1" },
                        [
                          _c(
                            "b-input-group",
                            { staticClass: "input-group-merge" },
                            [
                              _c(
                                "b-input-group-prepend",
                                { attrs: { "is-text": "" } },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "SearchIcon" }
                                  })
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  id: "icons-search",
                                  placeholder: "Search"
                                },
                                model: {
                                  value: _vm.filters["name"],
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "name", $$v)
                                  },
                                  expression: "filters['name']"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "ml-1 mr-1 scrollable-menu",
                          on: { click: _vm.repeateAgain }
                        },
                        _vm._l(_vm.rolesOption, function(option) {
                          return _c(
                            "b-dropdown-item",
                            {
                              key: option.id,
                              attrs: { value: option.name },
                              on: {
                                click: function($event) {
                                  _vm.selectedRole = option
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(option.name) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("b-col", [_c("hr")])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex ml-1 mt-1" },
          [
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "ripple",
                    rawName: "v-ripple.400",
                    value: "rgba(255, 255, 255, 0.15)",
                    expression: "'rgba(255, 255, 255, 0.15)'",
                    modifiers: { "400": true }
                  }
                ],
                attrs: {
                  type: "submit",
                  disabled: _vm.isActive,
                  variant: "primary"
                },
                on: {
                  click: function($event) {
                    return _vm.updateRoles()
                  }
                }
              },
              [_vm._v(" Save ")]
            ),
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "ripple",
                    rawName: "v-ripple.400",
                    value: "rgba(186, 191, 199, 0.15)",
                    expression: "'rgba(186, 191, 199, 0.15)'",
                    modifiers: { "400": true }
                  }
                ],
                staticClass: "text-primary ml-1 shadow",
                attrs: { type: "button", variant: "outline-white" },
                on: {
                  click: function($event) {
                    return _vm.cancelRecords()
                  }
                }
              },
              [_vm._v(" Cancel ")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }