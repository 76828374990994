<template>
<b-sidebar
      id="is-add-shift-templates-sidebar-active"
      :visible="isAddSiftTemplatesSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @hidden="resetForm"
  >
    <template #default="{ hide }">
      <b-overlay
          :show="tableLoading"
          rounded="sm"
      >

        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4 class="mb-0">
            Create shift Template
          </h4>

          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="cancel(); hide()"
          />

        </div>
        <div class="p-2">

          <b-row>

            <b-col md="12">
              <b-form-group>
                <label></label>
                <!--                      rules="required"-->
                <validation-provider
                    #default="{ errors }"
                    name="Service"
                    :rules="'required'"
                >
                  <v-select
                      v-model="form.service_id"
                      :options="services"
                      label="name"
                      placeholder="Role/ Activity"
                      :reduce="services => services.id"
                  >
                    <span slot="no-options">
                                Please add services first
                        </span>
                  </v-select>
                  <small  class="text-danger" v-if="errors[0]">The Role/Activity field is required</small>
                </validation-provider>
              </b-form-group>

            </b-col>
            <b-col cols="12" md="4">
              <label>Start Time</label>
              <b-form-group>
                <validation-provider
                    ref="endTime"
                    #default="{ errors }"
                    :bails="false"
                    name="End Time"
                    rules="required"
                >
                  <b-form-group>
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="ClockIcon"/>
                      </b-input-group-prepend>
                      <flat-pickr
                          v-model="form.start_time"
                          :config="{ enableTime: true, noCalendar: true,  dateFormat: 'H:i', }"
                          class="form-control"
                          placeholder="Select"
                      />
                    </b-input-group>
                  </b-form-group>
                  <!-- <small  class="text-danger" v-if="errors[0]">The End Time field is required</small> -->
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <label>End Time</label>
              <validation-provider
                  ref="endTime"
                  #default="{ errors }"
                  :bails="false"
                  name="End Time"
                  rules="required"
              >
                <b-form-group>
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ClockIcon"/>
                    </b-input-group-prepend>
                    <flat-pickr
                        v-model="form.end_time"
                        :config="{ enableTime: true, noCalendar: true,  dateFormat: 'H:i', }"
                        class="form-control"
                        placeholder="Select"
                    />
                  </b-input-group>
                </b-form-group>
                <!-- <small  class="text-danger" v-if="errors[0]">The End Time field is required</small> -->
              </validation-provider>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group
                  label="Break (mins)"
                  label-for="login-minutes"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Break Minutes"
                    rules="numeric"
                >
                  <b-form-input
                      id="login-minutes"
                      v-model="form.break_minutes"
                      name="login-minutes"
                      autocomplete="off"
                      min="0"
                      type="number"
                      value="0"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                </validation-provider>
              </b-form-group>
            </b-col>
         </b-row>
         <div class="mt-n2" :hidden=" form.start_time  && form.end_time && (form.start_time < form.end_time)  ">
             <small class="text-danger">The End Time should be after the Start Time</small>
          </div>
          <div class="mt-1">
            <label>Notes (optional)</label>
            <validation-provider
                #default="{ errors }"
                name="Notes"
                rules=""
            >
            <b-form-textarea
                id="textarea-default"
                v-model="form.note"
                placeholder="Note"
                rows="3"
            />
              <!-- <small class="text-danger">{{ errors[0] }}</small> -->
            </validation-provider>
          </div>

          <div class="d-flex mt-3">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                :disabled=" !form.service_id || form.start_time >= form.end_time || !form.start_time || !form.end_time "
                @click="addShiftTemplate()"
            >
              Add
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="shadow text-primary ml-1"
                type="button"
                variant="outline-white"
                @click="cancel(); hide()"
            >
              Cancel
            </b-button>
          </div>
        </div>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BLink,
  BListGroup,
  BListGroupItem,
  BMedia,
  BOverlay,
  BPagination,
  BRow,
  BSidebar,
  BTable,
  BFormTextarea,
  VBTooltip
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import settings from '@/apis/modules/settings'
import flatPickr from 'vue-flatpickr-component'


export default {
  name: 'addShiftTemplatesSideBar',

  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BRow,
    BCol,
    BInputGroupPrepend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BCard,

    BAvatar,
    BLink,
    BBadge,
    BCardBody,
    BCardHeader,
    BImg,
    BMedia,
    BTable,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    BOverlay,
    flatPickr,
    BFormTextarea,

    VBTooltip
  },

  directives: {
    Ripple
  },
  model: {
    prop: 'isAddSiftTemplatesSidebarActive',
    event: 'update:is-add-shift-templates-sidebar-active'
  },
  props: {
    isAddSiftTemplatesSidebarActive: {
      type: Boolean,
      required: true
    },
    roleOptions: {
      type: Array,
      required: false
    },
    planOptions: {
      type: Array,
      required: false
    }
  },
  // watch: {
  //   currentPage () {
  //
  //     this.getService()
  //   }
  // },
  data () {
    return {

      hide_btn: false,
      services: [],
      tableLoading: false,
      form: {
        start_time: '',
        end_time: '',
        service_id: '',
        break_minutes: 0,
        note: ''
      }
    }
  },

  watch: {
    isAddSiftTemplatesSidebarActive (val) {
      if (val) this.getService()
    },
    selected (val) {
      // console.log(val)
    }
  },

  methods: {
    async addShiftTemplate(){

      this.tableLoading = true
      const response = await settings.createShiftTemplate(this.form)
      if(response.data.message === 'error'){
        this.showErrorMessage('Time is invalid')
        this.tableLoading = false
      }else {
        this.showSuccessMessage('Template Added Successfully')
        this.$emit('dataRefresh')
        this.tableLoading = false

        try {

        } catch (error) {
          this.convertAndNotifyError(error)
          this.tableLoading = false
        }
        this.form.start_time = null
        this.form.end_time = null
        this.form.service_id = null
        this.form.break_minutes = null
        this.form.note = null
      }

    },
    resetForm() {

    },
    toggle() {
      this.isAddSiftTemplatesSidebarActive = false
          this.form.start_time =null
          this.form.end_time= null
          this.form.service_id= null
          this.form.break_minutes= null
          this.form.note= null
    },
    onRowSelected(items) {
      // this.selected = items
    },

    async getService() {
      try {
        this.tableLoading = true
        const serviceResponse = await settings.getServicesInPractice()

        this.services = serviceResponse.data.data.map((x) => ({
          name: x.name,
          id: x.id
        }))
        this.tableLoading = false

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    cancel(){
      this.form.start_time =null
      this.form.end_time= null
      this.form.service_id= null
      this.form.break_minutes= null
      this.form.note= null

    },

  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }

  //.table thead th {
  //  border-bottom: 2px solid #ebe9f1;
  //  visibility: hidden !important;
  //}
}
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }

}
</style>

<style lang="scss">
#services_table td:nth-child(2) {
  padding-left: 15px !important;
}
@import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
