var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "mb-0", attrs: { "no-body": "" } }, [
        _c(
          "div",
          {},
          [
            _c(
              "b-button",
              {
                staticClass: " float-right mb-1",
                attrs: { size: "sm", variant: "primary" },
                on: {
                  click: function($event) {
                    return _vm.toggleSideBars()
                  }
                }
              },
              [
                _c("span", { staticClass: "align-middle fs-4" }, [
                  _vm._v("+ Create Role")
                ])
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "b-overlay",
              { attrs: { show: _vm.tableLoading, rounded: "md" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("b-table", {
                          ref: "table",
                          staticClass: "mobile_table_css",
                          attrs: {
                            "current-page": _vm.currentPage,
                            "per-page": _vm.pagination.perPage,
                            fields: _vm.fields,
                            filter: _vm.filter,
                            "filter-included-fields": _vm.filterOn,
                            items: _vm.getRole,
                            "sort-by": _vm.sortBy,
                            "sort-desc": _vm.sortDesc,
                            "sort-direction": _vm.sortDirection,
                            hover: "",
                            responsive: ""
                          },
                          on: {
                            "update:sortBy": function($event) {
                              _vm.sortBy = $event
                            },
                            "update:sort-by": function($event) {
                              _vm.sortBy = $event
                            },
                            "update:sortDesc": function($event) {
                              _vm.sortDesc = $event
                            },
                            "update:sort-desc": function($event) {
                              _vm.sortDesc = $event
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "cell(Name)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "200px" } },
                                    [_vm._v(" " + _vm._s(data.item.Name) + " ")]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(Profession)",
                              fn: function(data) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          data.item.Profession.join(" , ")
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "cell(Type)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "100px" } },
                                    [_vm._v(" " + _vm._s(data.item.Type) + " ")]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(action)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {},
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.top",
                                                  value: "Edit",
                                                  expression: "'Edit'",
                                                  modifiers: {
                                                    hover: true,
                                                    top: true
                                                  }
                                                }
                                              ],
                                              staticClass:
                                                "btn-icon shadow-sm bg-white text-primary",
                                              attrs: {
                                                size: "23",
                                                variant: "outline-white"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.editShifts(
                                                    data.item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("feather-icon", {
                                                attrs: {
                                                  size: "18",
                                                  icon: "EditIcon"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mx-1 mb-2" },
                  [
                    _c(
                      "b-row",
                      [
                        _vm.noDataTable === 0
                          ? _c(
                              "b-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: "12" }
                              },
                              [_c("span", [_vm._v("No data for preview")])]
                            )
                          : _vm._e(),
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center justify-content-sm-start",
                            attrs: { cols: "12", sm: "6" }
                          },
                          [
                            _vm.pagination.totalRows !== 0
                              ? _c("span", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    "Showing " +
                                      _vm._s(_vm.pagination.from) +
                                      " to " +
                                      _vm._s(_vm.pagination.to) +
                                      " of " +
                                      _vm._s(_vm.pagination.totalRows) +
                                      " entries"
                                  )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center justify-content-sm-end",
                            attrs: { cols: "12", sm: "6" }
                          },
                          [
                            _c("b-pagination", {
                              staticClass: "mb-0 mt-1 mt-sm-0 ",
                              attrs: {
                                "per-page": _vm.pagination.perPage,
                                "total-rows": _vm.pagination.totalRows,
                                "first-number": "",
                                "last-number": "",
                                "next-class": "next-item",
                                "prev-class": "prev-item"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "prev-text",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "ChevronLeftIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "next-text",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "ChevronRightIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ]),
                              model: {
                                value: _vm.currentPage,
                                callback: function($$v) {
                                  _vm.currentPage = $$v
                                },
                                expression: "currentPage"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("AddRoles", {
        ref: "AddRoleSideBar",
        attrs: { isAddRoles: _vm.isAddRoles },
        on: {
          toggleSideBars: function($event) {
            return _vm.toggleSideBars()
          },
          dataRefresh: function($event) {
            return _vm.dataRefresh()
          }
        }
      }),
      _c("EditRoleSideBar", {
        ref: "EditLocationSideBar",
        attrs: {
          "selected-data": _vm.selectedData,
          isEditRoleSidebarActive: _vm.isEditRoleSidebarActive
        },
        on: {
          "editShifts()": function($event) {
            return _vm.editShifts()
          },
          dataRefresh: function($event) {
            return _vm.dataRefresh()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }