<template>
  <div>




    <b-row>
      <b-col cols="12" class="mb-2">
        <b-button class="float-right" size="sm" variant="primary" @click="sendInvitation()">
          <feather-icon class="mr-50" icon="SendIcon"/>
          <span>Send admin access invitation</span>
        </b-button>
      </b-col>

      <b-col cols="12">
        <b-overlay
            :show="tableLoading"
            rounded="sm"
        >
        <b-table
            :current-page="currentPage"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="filterOn"
            :items="getInvitations"
            :per-page="pagination.perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            class="mobile_table_css"
            ref="table"
            hover
            responsive
        >

          <template v-slot:head(send_date)="data">
            <span v-b-tooltip.hover.top="'Sort'" >{{ data.label }}</span>
          </template>

          <!-- Name $ Avatar -->

                   <template #cell(email)="data">
                    <div style="padding-top:8px; padding-bottom:8px">
                      {{ data.item.email }}
                    </div>
                   </template>


          <!-- Delete User Button -->

          <template #cell(action)="data">
            <div>
              <b-badge v-if="data.item.state ==='Accepted'" class="ml-1" style="padding:8px" variant="light-white" >
              </b-badge>
              <b-button
                  v-if="data.item.state !=='Accepted'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon mr-2"
                  v-b-tooltip.hover.top="'Resend'"
                  @click="resendInvitation(data.item.id)"

              >
                <feather-icon icon="SendIcon" />
              </b-button>
              <b-button
                  v-if="data.item.state !=='Accepted'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="btn-icon"
                  @click="deleteInvitation(data.item.id)"
                  v-b-tooltip.hover.top="'Delete'"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
<!--            <b-dropdown-->
<!--                no-caret-->
<!--                toggle-class="text-decoration-none"-->
<!--                variant="link"-->
<!--            >-->
<!--              <template v-slot:button-content>-->
<!--                <feather-icon-->
<!--                    class="text-body align-middle mr-25"-->
<!--                    icon="MoreVerticalIcon"-->
<!--                    size="16"-->
<!--                />-->
<!--              </template>-->
<!--              -->
<!--              <b-dropdown-item>-->
<!--                <b-button variant="outline-white" @click="resendInvitation(data.item.id)">-->
<!--                  <feather-icon class="mr-50" icon="SendIcon"/>-->
<!--                  <span>Resend</span>-->
<!--                </b-button>-->
<!--              </b-dropdown-item>-->
<!--              <b-dropdown-item>-->
<!--                <b-button variant="outline-white" @click="deleteInvitation(data.item.id)">-->
<!--                  <feather-icon class="mr-50" icon="TrashIcon"/>-->
<!--                  <span>Delete</span>-->
<!--                </b-button>-->
<!--              </b-dropdown-item>-->
<!--            </b-dropdown>-->
          </template>


        </b-table>
        </b-overlay>
      </b-col>

      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
        <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
      </b-col>

      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

        <b-pagination
            v-model="currentPage"
            :per-page="pagination.perPage"
            :total-rows="pagination.totalRows"
            class="mb-0 mt-1 mt-sm-0 "
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item">

          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18"/>
          </template>

          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18"/>
          </template>

        </b-pagination>

      </b-col>
    </b-row>
    <b-modal
        id="modal-center"
        v-model="showSendInvitation"
        no-close-on-backdrop
        centered
        hide-footer
        size="lg"
        title="Send Invitation"
    >
      <b-overlay
          :show="modelShow"
          rounded="sm"
      >
      <b-row class="mx-1">
        <b-col cols="12" md="12">
          <validation-observer ref="InvitationSendForm">
            <b-form @submit.prevent>
              <b-row>
                <b-col cols="12">
                  <b-form-group class="">
                    <label>Email</label>
                    <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                            id="name"
                            v-model="email"
                            type="text"
                            placeholder="Enter email address"
                            autocomplete="none"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="" md="12"></b-col>
              </b-row>


              <div class="float-right mb-1">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mr-1"
                    type="submit"
                    variant="primary"
                    @click="sendInvitationForm()"
                >
                  Send
                </b-button>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                    @click="resetData()"
                >
                <span class="text-secondary">
                  Reset
                </span>
                </b-button>
              </div>

            </b-form>

          </validation-observer>

        </b-col>


      </b-row>
      </b-overlay>

    </b-modal>



  </div>

</template>

<script>

import {BCard, BTabs, BTab, BButton, BDropdown, BDropdownItem, VBTooltip} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import vSelect from 'vue-select'


import {

  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BAlert,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormInvalidFeedback,
  BSidebar,
  BFormTextarea,
  VBModal,
  BOverlay
} from 'bootstrap-vue'
import settings from '@/apis/modules/settings'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import Ripple from 'vue-ripple-directive'


export default {
  name: 'users-invitations',
  components: {
    vSelect,
    BInputGroupPrepend,
    BCard,
    BButton,
    BTabs,
    BTab,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormInvalidFeedback,
    BSidebar,
    BFormTextarea,
    VBTooltip,
    BOverlay

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],
  data () {
    return {
      tableLoading: false,
      modelShow: false,
      email: '',
      system_role_ids: '',
      job_role_ids: '',
      showSendInvitation:false,
      noDataTable:'',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      fields: [

        {
          key: 'email',
          label: 'EMAIL'
        },
        {
          key: 'state',
          label: 'State'
        },
        {
          key: 'send_date',
          label: 'Sent Date',
          sortable: true
        },
        {
          key: 'action',
          label: 'Action'
        }

      ],
      items: [],
      jobRoleOption: [],
      systemRoleOption:[]
    }
  },
  methods: {
    async systemRoleList (){
      try {
        const systemRoleResponse =  await settings.SystemJobRoleList()
        this.systemRoleOption = systemRoleResponse.data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },

    async jobRoleList (){
      try {
        const practiceListResponse =  await settings.getJobRoleList()
        this.jobRoleOption = practiceListResponse.data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    resetData () {
      this.email = ''
      this.system_role_ids = ''
      this.job_role_ids = ''
    },
    async sendInvitationForm () {
      if (await this.$refs.InvitationSendForm.validate()) {
        try {
          this.modelShow = true
          const payload = {
            email: this.email,
            system_role_ids: [1],
            // job_role_id:this.job_role_ids.id
          }
          await settings.sendInvitation( payload)
          this.showSuccessMessage('Invitation sent successfully')
          this.showSendInvitation = false
          this.modelShow = false
          this.$refs.table.refresh()
          this.resetData()
          await this.getInvitations()
          await this.systemRoleList()
        } catch (e) {
          this.convertAndNotifyError(e)
          this.modelShow = false
        }
      }
    },
    async sendInvitation () {
      try {
        this.showSendInvitation = true
        await this.jobRoleList ()
        await this.systemRoleList()
      } catch (error) {
        this.convertAndNotifyError(error)
      }

    },
    async resendInvitation (id) {
      try {
        this.tableLoading = true
        await settings.resendInvitation( id)
        this.showSuccessMessage('Resend Invitation Successfully')
        await this.getInvitations()
        this.tableLoading = false
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false

      }
    },
    async deleteInvitation (id) {
      try {
        this.tableLoading = true
        this.$swal({
          title: 'Are you sure to delete this invitation?',
          text: "This action cannot be reverted.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            await settings.deleteInvitation(id)
            this.$refs.table.refresh()
            this.showSuccessMessage('Your invitation has been deleted!')
            // this.$swal({
            //   icon: 'success',
            //   title: 'Deleted!',
            //   text: 'Your invitation has been deleted.',

            //   customClass: {
            //     confirmButton: 'btn btn-success',
            //   },
            // })
          } else if (result.dismiss === 'cancel') {
            this.$refs.table.refresh()
            this.tableLoading = false
            // this.$swal({
            //   title: 'Cancelled',
            //   text: ' ',

            //   icon: 'error',
            //   customClass: {
            //     confirmButton: 'btn btn-success',
            //   },
            // })
          }
        })
        // await settings.deleteInvitation( id)
        // this.showSuccessMessage('Delete Invitation Successful')
        // this.$refs.table.refresh()
        // this.tableLoading = false
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false

      }
    },

    async getInvitations () {
      try {
        this.tableLoading = true
        const Response = await settings.invitationList()
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          email: x.email,
          state: x.state.charAt(0).toUpperCase()+ x.state.slice(1),
          send_date: this.momentFormat(x.created_at, 'ddd DD MM YYYY')
        }))
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage= paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false
        return dataArray
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    }
  },
  mounted () {
    // Set the initial number of items
    this.totalRows = this.items.length


  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
