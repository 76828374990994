var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "mp-0" },
    [
      _c(
        "b-tabs",
        {
          model: {
            value: _vm.tabIndex,
            callback: function($$v) {
              _vm.tabIndex = $$v
            },
            expression: "tabIndex"
          }
        },
        [
          _c("b-tab", { attrs: { title: "Active Users", lazy: "" } }, [
            _c("div", { staticClass: "alert-body" }, [_c("ActiveUser")], 1)
          ]),
          _c("b-tab", { attrs: { title: "Archive Users", lazy: "" } }, [
            _c("div", { staticClass: "alert-body" }, [_c("ArchiveUser")], 1)
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }