var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("hr"),
      _c(
        "b-card",
        {},
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "5" } }, [
                _c("ul", { staticClass: "list-inline mb-0 pt-1" }, [
                  _c(
                    "li",
                    { staticClass: "list-inline-item" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { name: "check-button", switch: "" },
                          on: { change: _vm.toggle },
                          model: {
                            value: _vm.is_allow_cancel_48_hours,
                            callback: function($$v) {
                              _vm.is_allow_cancel_48_hours = $$v
                            },
                            expression: "is_allow_cancel_48_hours"
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "switch-icon-left" },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "CheckIcon" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "switch-icon-right" },
                            [_c("feather-icon", { attrs: { icon: "XIcon" } })],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("li", { staticClass: "list-inline-item text-secondary" }, [
                    _vm._v(" Prevent shift cancellation within 48 hours ")
                  ])
                ])
              ]),
              _c("b-col", { attrs: { cols: "7" } }, [
                _c("div", { staticClass: "d-flex ml-1" })
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("hr"),
      _c(
        "b-card",
        {},
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "5" } }, [
                _c("ul", { staticClass: "list-inline mb-0 pt-1" }, [
                  _c(
                    "li",
                    { staticClass: "list-inline-item" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { name: "check-button", switch: "" },
                          on: { change: _vm.toggle1 },
                          model: {
                            value:
                              _vm.vacant_shifts_applications_require_approval,
                            callback: function($$v) {
                              _vm.vacant_shifts_applications_require_approval = $$v
                            },
                            expression:
                              "vacant_shifts_applications_require_approval"
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "switch-icon-left" },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "CheckIcon" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "switch-icon-right" },
                            [_c("feather-icon", { attrs: { icon: "XIcon" } })],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("li", { staticClass: "list-inline-item text-secondary" }, [
                    _vm._v(
                      "Staff applications for shifts do not require approval"
                    )
                  ])
                ])
              ]),
              _c("b-col", { attrs: { cols: "7" } }, [
                _c("div", { staticClass: "d-flex ml-1" })
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("hr"),
      _c(
        "b-card",
        {},
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "5" } }, [
                _c("ul", { staticClass: "list-inline mb-0 pt-1" }, [
                  _c(
                    "li",
                    { staticClass: "list-inline-item" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { name: "check-button", switch: "" },
                          on: { change: _vm.ShiftSwapStateChange },
                          model: {
                            value: _vm.is_approve_shift_swap,
                            callback: function($$v) {
                              _vm.is_approve_shift_swap = $$v
                            },
                            expression: "is_approve_shift_swap"
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "switch-icon-left" },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "CheckIcon" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "switch-icon-right" },
                            [_c("feather-icon", { attrs: { icon: "XIcon" } })],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("li", { staticClass: "list-inline-item text-secondary" }, [
                    _vm._v(" Automatically approve shift swaps")
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }