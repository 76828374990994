<template>
  <b-row>
    <b-col cols="12">
      <b-table
        :current-page="currentPage"
        :fields="fields"
        :items="getUsers"
        :per-page="pagination.perPage"
        class="mobile_table_css"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        hover
        responsive
        ref="table"
      >
        <template #cell(first_name)="data">
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <b-avatar
                :src="getUserImage(data.item.id, true)"
                class="pull-up"
              />
            </li>
            <li class="list-inline-item">{{ data.item.first_name }}</li>
            <b-badge
              v-for="role in data.item.role"
              :key="role.id"
              class="ml-1"
              style="padding: 8px"
              variant="light-info"
            >
              <!--              <span class="text-dark font-weight-700"> {{ role.name }} </span>-->
            </b-badge>
          </ul>
        </template>

        <template style="width: 10%" #cell(type_of_access)="data">
          <span v-for="(type, index) in data.item.type_of_access" :key="index">
            <b-badge class="" variant="light-primary ml-1">{{ type.name }}</b-badge>
            <br>
          </span>
        </template>

          <template #cell(is_archive)="data">
            <b-badge variant="light-danger">{{
              data.item.is_archive
            }}</b-badge>
          </template>

        <!-- Delete User Button -->

        <template #cell(action1)="data">
<!--          <b-row>-->
<!--          <b-col md="6">-->
          <div class="d-flex flex-row">


          <div class="float-left">
            <b-button
                v-b-tooltip.hover.top="'Active staff'"
                variant="gradient-primary mt-1"
                class="btn-icon"
                @click="addAgainUser(data.item.id)"
                size="sm"
            >
              <feather-icon icon="UserPlusIcon" />
            </b-button>
          </div>
          </div>

<!--          </b-col>-->
<!--          <b-col md="6">-->

            <!--            <b-button
                v-b-tooltip.hover.top="'Remove User'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="btn-icon m-1"
                variant="danger"
                @click="confirmButtonColor()"
            >
              <feather-icon icon="XSquareIcon"/>
            </b-button>-->
<!--          </b-col>-->
<!--          </b-row>-->
        </template>

      </b-table>
      <div></div>
    </b-col>
    <b-col v-if="pagination.totalRows === 0" class="text-center" cols="12">
      <span>No data for preview</span>
    </b-col>
    <!-- Pagination -->

    <b-col
      class="
        d-flex
        align-items-center
        justify-content-center justify-content-sm-start
      "
      cols="12"
      sm="6"
    >
      <span v-if="pagination.totalRows !== 0" class="text-muted"
        >Showing {{ pagination.from }} to {{ pagination.to }} of
        {{ pagination.totalRows }} entries</span
      >
    </b-col>

    <!-- Pagination -->

    <b-col
      class="
        d-flex
        align-items-center
        justify-content-center justify-content-sm-end
      "
      cols="12"
      sm="6"
    >
      <b-pagination
        v-model="currentPage"
        :per-page="pagination.perPage"
        :total-rows="pagination.totalRows"
        class="mb-0 mt-1 mt-sm-0"
        first-number
        last-number
        next-class="next-item"
        prev-class="prev-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>

        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAlert,
  BCard,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormInvalidFeedback,
  BFormTextarea,
  BInputGroupPrepend,
  BOverlay,
  BSidebar,
  BTab,
  BTabs,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import store from "../../../store/profile/index";
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";
import settings from "@/apis/modules/settings";
import staffBank from "@/apis/modules/staffBank";
import ErrorMessages from "@/mixins/ErrorMessages";
import SuccessMessage from "@/mixins/SuccessMessage";
import MomentMixin from "@/mixins/MomentMixin";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mpAppUrl, pcnAppUrl, loginAppUrl } from "@/constants/config";
import practice from "@/apis/modules/practice";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
    BInputGroupPrepend,
    BCard,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormInvalidFeedback,
    BSidebar,
    BFormTextarea,
    VBTooltip,
    BOverlay,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],

  data() {
    return {
      showEditRoleModel: false,
      noDataTable: "",
      currentPage: 1,
      pagination: {
        perPage: "",
        pageOptions: "",
        totalRows: "",
        from: "",
        to: "",
      },
      sortBy: "",
      sortDesc: true,
      sortDirection: "asc",
      // filter: null,
      // filterOn: [],
      // infoModal: {
      //   id: 'info-modal',
      //   title: '',
      //   content: ''
      // },
      fields: [
        {
          key: "first_name",
          label: "NAME",
          sortable: true,
        },
        {
          key: "email",
          label: "EMAIL",
          sortable: true,
        },
        {
          key: "type_of_access",
          label: "Type of access",
        },
        // {
        //   key: "account_created",
        //   label: "Date account created",
        // },
        {
          key: "is_archive",
          label: "Status",
        },
        {
          key: "action1",
          label: "",
        },
        {
          key: "action2",
          label: "",
        },

      ],
      items: [],
      jobRoleOption: [],
      systemRoleOption: [],
      user_name: "",
      user_email: "",
      user_id: "",
      modelShow: false,
      email: "",
      system_role_ids: [],
      job_role_id: "",
    };
  },

  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    async systemRoleList() {
      try {
        const systemRoleResponse = await settings.SystemJobRoleList();
        this.systemRoleOption = systemRoleResponse.data.data;
      } catch (e) {
        this.convertAndNotifyError(e);
      }
    },

    async jobRoleList() {
      try {
        const practiceListResponse = await settings.getJobRoleList();
        this.jobRoleOption = practiceListResponse.data.data;
      } catch (e) {
        this.convertAndNotifyError(e);
      }
    },
    async getUsers() {
      try {
        // const Response = await settings.userList(this.sortBy)
        if (this.sortBy === "") {
          this.sortDesc = true;
        }
        const Response = await settings.archiveUserList(
          this.currentPage,
          10,
          this.sortBy,
          this.sortDesc
        );
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          first_name: x.first_name + " " + x.last_name,
          email: x.email,
          is_archive: x.pivot.is_archive === 0 ? "Active" : "Archived",
          // type_of_access: x.phone_number ? x.phone_number : 'N/A',
          account_created: this.momentFormat(x.created_at, "ddd DD MM YYYY"),
          job_role: x.job_roles[0],
          system_role: x.roles,
          type_of_access: x.roles.map((z) => ({
            name: z.name,
          })),
        }));

        const paginationResponse = Response.data.meta;
        this.currentPage = paginationResponse.current_page;
        this.pagination.totalRows = paginationResponse.total;
        this.pagination.perPage = paginationResponse.per_page;
        this.pagination.from = paginationResponse.from;
        this.pagination.to = paginationResponse.to;
        this.tableLoading = false;
        return dataArray;
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },


    addAgainUser(id) {
      try {
        this.$swal({
          title: "Are you sure you want to Add again this user?",
          text: "",
          imageUrl: require("@/assets/images/icons/publish.png"),
          imageWidth: 80,
          imageHeight: 80,
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No, go back",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
           await staffBank.addAgain(id);
            this.$refs.table.refresh();
          }
        });
      } catch (e) {
        this.convertAndNotifyError(e);
      }
    },


    resetData() {
      this.user_name = "";
      this.user_email = "";
      this.system_role_ids = "";
      this.job_role_id = "";
    },
  },

  mounted() {
    // Set the initial number of items
  },
};
</script>
<style>
div#dropdown-1 .btn-outline-danger {
  border: none !important;
}
</style>
